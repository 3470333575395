<template>
  <div>
    <div class="h-full p-5">
      <el-form
        ref="form"
        label-width="120px"
        class="h-full overflow-y-auto"
        :model="adsMsg"
        :disabled="currentData.disabled"
        :rules="rules"
      >
        <el-form-item
          label="广告组名称"
          prop="name"
        >
          <el-input
            v-model="adsMsg.name"
            placeholder="广告组名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告组状态">
          <el-switch
            v-model="adsMsg.status"
            active-text="开启"
            inactive-text="暂停"
            active-value="ENABLED"
            inactive-value="PAUSED"
          >
          </el-switch>
        </el-form-item>
        <!-- 竞价 -->
        <el-form-item
          label="竞价策略"
          prop="bidStrategy"
        >
          <el-radio-group
            v-model="adsMsg.bidStrategy"
            @change="changeBidStrategy"
          >
            <el-radio
              :label="item.value"
              v-for="item in enumConstants.bidStrategy"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=""
          v-if="adsMsg.bidStrategy != 'AUTO'"
          prop="bidAmount"
        >
          <el-input
            v-model="adsMsg.bidAmount"
            placeholder="请输入出价金额"
            style="width: 240px"
            type="number"
            @change="changeBid"
          >
            <span slot="append">/转化</span>
          </el-input>
        </el-form-item>
        <p class="font-semibold text-[#666] text-[16px]">定向</p>
        <el-form-item
          label="地区"
          prop="groupCriterion.target.locations"
        >
          <div class="flex">
            <el-select
              class="flex-1"
              multiple
              filterable
              default-first-option
              value-key="country_code"
              v-model="adsMsg.groupCriterion.target.locations"
              @change="(v) => onChangeLocations(v, 'locations')"
            >
              <el-option
                v-for="item in enumConstants.countries"
                :key="item.country_code"
                :label="item.name_zhcn"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="语言">
          <el-select
            multiple
            class="w-full"
            filterable
            default-first-option
            v-model="adsMsg.groupCriterion.target.languages"
          >
            <el-option
              v-for="languages in enumConstants.languages"
              :label="languages.nameZhcn"
              :value="languages.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <p class="font-semibold text-[#666] text-[16px]">受众</p>
        <el-form-item label="受众群体">
          <AudienceGroup
            ref="audienceGroup"
            v-model="adsMsg.groupCriterion.audience.resourceName"
            :accountId="createAdAccount.id"
            :currentAccount="createAdAccount"
            @selectAudienceGroup="selectAudienceGroup"
          />
        </el-form-item>
        <el-form-item label="性别">
          <AudienceSex
            v-model="adsMsg.groupCriterion.audience.genders"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item label="年龄">
          <AudienceAge
            v-model="adsMsg.groupCriterion.audience.ageRange"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item label="自定义细分受众">
          <CustomAudience
            ref="customAudience"
            :currentAccount="createAdAccount"
            :disabled="true"
            v-model="adsMsg.groupCriterion.audience.customAudiences"
          />
        </el-form-item>
        <el-form-item label="兴趣和详细受众">
          <InterestAudience
            ref="interestAudience"
            v-model="adsMsg.groupCriterion.audience.userInterests"
            :list="interestList"
            :interestLabelSaveAPI="interestLabelSaveAPI"
            :countryCodes="adsMsg.groupCriterion.target.locations"
            :accountId="createAdAccount.id"
            :defaultExpandAll="false"
            :disabled="true"
          ></InterestAudience>
        </el-form-item>
        <el-form-item label="优化型定位">
          <OptimizePosition
            :value="adsMsg"
            @input="(v) => setAudiencePosition(v)"
          />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import AudienceAge from '@/views/google/create/components/audience/AudienceAge/index.vue';
import AudienceGroup from '@/views/google/create/components/audience/AudienceGroup/index.vue';
import InterestAudience from '@/views/google/create/components/audience/interestAudiences/index.vue';
import CreateSegmentAudience from '@/views/google/create/components/audience/createSegmentAudience/index.vue';
import OptimizePosition from '@/views/google/create/components/audience/OptimizePosition/index.vue';
import CustomAudience from '@/views/google/create/components/audience/CustomAudience/index.vue';
import AudienceSex from '@/views/google/create/components/audience/AudienceSex/index.vue';
import { callToActionList, sexList, interestList } from '@/views/google/create/index.js';
import { adDetail, adConstMapGG, saveCommonInterests, updateAd } from '@/api/google/create.js';
import { mapState } from 'vuex';
export default {
  props: {
    enumConstants: {
      type: Object,
      default: () => ({}),
    },
    currentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    createAdAccount: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      interestList: [],
      interestLabelSaveAPI: saveCommonInterests,
      adsMsg: {
        groupCriterion: {
          target: {},
          audience: {},
        },
      },
      checkData: {
        bidMin: 0.01,
        bidMax: 1000000,
      },
    };
  },
  components: {
    AudienceGroup,
    AudienceAge,
    InterestAudience,
    CreateSegmentAudience,
    OptimizePosition,
    CustomAudience,
    AudienceSex,
  },
  computed: {
    rules() {
      return {
        name: [{ required: true, message: '广告组名称不能为空' }],
        'groupCriterion.target.locations': [
          {
            required: true,
            message: '请选择地区',
          },
          {
            validator(rule, value, callback) {
              if (value.length == 0) {
                return callback(new Error('请选择地区'));
              }
              callback();
            },
            trigger: ['blur'],
          },
        ],
        bidAmount: [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.checkData.bidMin || value > this.checkData.bidMax)) {
                return callback(new Error(`出价不能高于${this.checkData.bidMax} 不能低于${this.checkData.bidMin}`));
              } else if (!value) {
                return callback(new Error(`出价不能高于${this.checkData.bidMax} 不能低于${this.checkData.bidMin}`));
              }
              callback();
            },
            message: `出价在${this.checkData.bidMin}～${this.checkData.bidMax}区间`,
            trigger: ['blur', 'change'],
          },
        ],
        bidStrategy: [{ required: true, message: '请选择竞价类型', trigger: ['change', 'blur'] }],
      };
    },
  },
  watch: {
    currentData: {
      handler() {
        this.adsMsg = this.currentData;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    checkForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          console.log(valid);
          if (!valid) {
            return resolve(false);
          }
          return resolve(true);
        });
      });
    },
    /**修改竞价策略 */
    changeBidStrategy(v) {
      this.adsMsg.bidAmount = null;
    },
    // 修改出价
    changeBid(v) {
      this.adsMsg.bidAmount = (this.adsMsg.bidAmount / 1).toFixed(2);
    },
    // 设置广告组受众地区
    onChangeLocations(value, key) {
      console.log(this.enumConstants.countries);
      console.log(value);
      let location = this.enumConstants.countries.filter((v) =>
        value.map((key) => key.country_code).includes(v.country_code),
      );
      let languagesDefault = [...new Set(location.map((v) => v.defaultLanguageIds).flat())];
      this.$set(this.adsMsg.groupCriterion.target, 'languages', languagesDefault);
    },
    // 选择受众群体
    selectAudienceGroup(v) {
      console.log(v);
      if (v) {
        this.adsMsg.groupCriterion.audience = { ...this.adsMsg.groupCriterion.audience, ...v };
      } else {
        this.adsMsg.groupCriterion.audience = {
          ageRange: {
            minAge: 18,
            maxAge: null,
            undetermined: true,
          }, //年龄
          genders: ['UNDETERMINED', 'MALE', 'FEMALE'], // 性别
          languages: [], // 语言
          userInterests: [], // 兴趣词
          customAudience: [], // 自定义受众
          ...this.adsMsg.groupCriterion.audience,
        };
      }
    },
    // 设置受众定位
    setAudiencePosition(v) {
      this.adsMsg = { ...this.adsMsg, ...v };
    },
  },
  created() {
    this.$nextTick(() => {
      console.log(this.$refs.audienceGroup);
      this.$refs.audienceGroup.getAudienceGroupList();
      this.$refs.customAudience.getCustomAudienceList();
    });
  },
};
</script>
<style lang="scss" scoped></style>
